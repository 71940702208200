.print-only {
    display: none !important;
}

@media print {
    @page {

        size: 210mm 297mm ;
        margin: 0mm 5mm;
    }

    body {
        min-width: 1100px !important;
    }

    table tr {
        background: transparent !important;
    }

    .print-only {
        display: block !important;
    }

    .no-print, [role="button"], a {
        display: none !important;
    }
}